var columns = [{
  title: '仓库名称',
  dataIndex: 'name',
  key: 'name',
  width: '10%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '国家',
  dataIndex: 'country',
  key: 'country',
  width: '10%',
  scopedSlots: {
    customRender: 'country'
  }
}, {
  title: '州，城市/省市区',
  dataIndex: 'state',
  key: 'state',
  width: '10%',
  scopedSlots: {
    customRender: 'state'
  }
},
// {
//     title: '城市',
//     dataIndex: 'city',
//     key: 'city',
//     width: '10%',
//     scopedSlots: {
//         customRender: 'city'
//     }
// }, 
{
  title: '地址',
  dataIndex: 'streetaddress',
  key: 'streetaddress',
  width: '20%',
  scopedSlots: {
    customRender: 'streetaddress'
  }
}, {
  title: '邮编',
  dataIndex: 'postalcode',
  key: 'postalcode',
  width: '10%',
  scopedSlots: {
    customRender: 'postalcode'
  }
}, {
  title: '仓库收件人',
  dataIndex: 'lastname',
  key: 'lastname',
  width: '10%',
  scopedSlots: {
    customRender: 'lastname'
  }
}, {
  title: '联系方式',
  dataIndex: 'mobile',
  key: 'mobile',
  width: '10%',
  scopedSlots: {
    customRender: 'mobile'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };