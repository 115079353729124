var searchKeys = [{
  key: "name",
  label: "仓库名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "countryid",
  label: "国家",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "postalcode",
  label: "邮编",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };